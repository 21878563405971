import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['drawer', 'slideOverPanel'];

  open() {
    this.slideOverPanelTarget.classList.remove('translate-x-full');
    this.slideOverPanelTarget.classList.add('translate-x-0');
  }

  close() {
    this.slideOverPanelTarget.classList.remove('translate-x-0');
    this.slideOverPanelTarget.classList.add('translate-x-full');
  }
}
